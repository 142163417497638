import * as _ from 'lodash';

type ConvertToStringI<Entry> = {
	columns: string[];
	labels: string[];
	data: Entry[];
};

type ExportToCSVI<Entry> = ConvertToStringI<Entry> & { file: string };

const convertToString = function <Entry>(args: ConvertToStringI<Entry>) {
	const { columns, labels, data } = args;

	let result = '';

	const columnDelimiter = ',';
	const lineDelimiter = '\n';

	result += labels.join(columnDelimiter);
	result += lineDelimiter;

	data.forEach((row: Entry) => {
		let ctr = 0;

		columns.forEach(column => {
			if (ctr > 0) result += columnDelimiter;

			let value = _.get(row, column) ?? null;

			result += value;

			ctr++;
		});

		result += lineDelimiter;
	});

	return result;
};

export const exportToCSV = function <Entry>(args: ExportToCSVI<Entry>) {
	const { file } = args;

	let csvString = convertToString(args);
	if (csvString === null) return;

	const link = document.createElement('a');

	const filename = `FDI EBM - ${file}.csv`;

	if (!csvString.match(/^data:text\/csv/i))
		csvString = `data:text/csv;charset=utf-8,${csvString}`;

	link.setAttribute('href', encodeURI(csvString));
	link.setAttribute('download', filename);
	link.click();
};

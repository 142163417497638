import { useInvoiceReadQuery } from '@/app/invoice/redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { PrinterIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { format } from '@/utils';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import rra_image from '@/assets/images/rra.png';
import mark from '@/assets/images/mark.png';
import { InvoiceModel } from '@/app/invoice';
import { Button, Header } from '@mantine/core';
import { LoaderIcon } from 'react-hot-toast';

export const InvoiceRead = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>();

    const { isLoading, isError, data } = useInvoiceReadQuery({
        id: invoiceId ?? '',
    });

    const invoiceRef = useRef<HTMLDivElement>(null);

    const handlePrintInvoice = useReactToPrint({
        content: () => invoiceRef.current,
    });

    if (isError) return <Navigate to="/invoice" />;

    return isLoading ? (
        <div className={'w-full h-[300px] flex justify-center items-center'}>
            <div className="w-10">
                <span className="animate-spin border-4 border-primary border-l-transparent rounded-full w-5 h-5 inline-block align-middle m-auto mb-10"></span>
            </div>
        </div>
    ) : (
        <>
            <div
                title={`Invoice #${data?.invoice_no}`}
                className={'flex flex-row justify-between items-center'}
            >
                <button
                    className={'btn btn-sm btn-primary gap-2'}
                    onClick={handlePrintInvoice}
                >
                    <PrinterIcon className="icon-sm w-4" />
                    Print to PDF
                </button>

                <Link to="/invoice" className="btn btn-sm btn-primary">
                    <ArrowLeftIcon className="icon-sm w-4 mr-3" />
                    Back
                </Link>
            </div>

            <div ref={invoiceRef}>
                <InvoiceCom data={data} />
            </div>
        </>
    );
};

export function InvoiceCom(props: { data: InvoiceModel | undefined }) {
    const { data } = props;
    return (
        <div className="mt-6 space-y-8 bg-white p-6">
            <div>
                <div className="flex w-full flex-col gap-2 md:flex-row md:items-center md:justify-between md:gap-6">
                    <div className="flex flex-row w-full  justify-between">
                        <div className="flex flex-row gap-3">
                            {data?.status === 'approved' && (
                                <img
                                    alt="qr-code"
                                    src={rra_image}
                                    className="w-28 object-cover"
                                />
                            )}

                            <div className="mt-2 ml-5 space-y-1 text-left text-xs font-normal">
                                <p>
                                    <span className="font-semibold uppercase">
                                        {data?.company.name}
                                    </span>
                                </p>

                                <p>
                                    <span className="font-semibold uppercase">
                                        TEL
                                    </span>
                                    : {data?.user?.phone}
                                </p>

                                <p>
                                    <span className="font-semibold uppercase">
                                        Email
                                    </span>
                                    : {data?.user?.email}
                                </p>

                                <p>
                                    <span className="font-semibold">TIN</span>:{' '}
                                    {data?.company.tin}
                                </p>
                            </div>
                        </div>

                        <img
                            alt="qr-code"
                            src={mark}
                            className="w-28 object-cover"
                        />
                    </div>
                </div>

                <div className="flex mt-4 w-full text-xs flex-row justify-between">
                    <div className="flex flex-col gap-2 w-full">
                        <p className="text-left font-semibold uppercase">
                            Invoice To
                        </p>
                        <div className="flex flex-row justify-between w-full">
                            <div className="flex flex-col w-1/3">
                                <div className="space-y-1 border border-black p-2 text-left flex flex-col justify-center font-normal">
                                    <p>
                                        <span className="font-semibold">
                                            TIN
                                        </span>
                                        : {data?.customer?.tin}
                                    </p>

                                    <p>
                                        <span className="font-semibold">
                                            Name
                                        </span>
                                        : {data?.customer?.name}
                                    </p>
                                </div>
                            </div>

                            <div className="space-y-1 w-1/3 border flex flex-col justify-center border-black p-2 text-left font-normal">
                                <p>
                                    <span className="font-semibold uppercase">
                                        Invoice No
                                    </span>
                                    : {data?.invoice_no}
                                </p>

                                <p>
                                    <span className="font-semibold">Date</span>:{' '}
                                    {dayjs(data?.created_at).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="overflow-x-auto">
                <table
                    style={{
                        borderCollapse: 'collapse',
                        border: '1px solid black',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <tbody>
                        <tr
                            style={{
                                backgroundColor: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            <th
                                style={{
                                    border: '1px solid black',
                                    padding: '5px 5px',
                                    textAlign: 'left',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    color: '#000',
                                }}
                            >
                                Item code
                            </th>
                            <th
                                style={{
                                    border: '1px solid black',
                                    padding: '5px 5px',
                                    textAlign: 'left',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    color: '#000',
                                }}
                            >
                                Item description
                            </th>
                            <th
                                style={{
                                    border: '1px solid black',
                                    padding: '5px 5px',
                                    textAlign: 'left',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    color: '#000',
                                }}
                            >
                                Quantity
                            </th>
                            <th
                                style={{
                                    border: '1px solid black',
                                    padding: '5px 5px',
                                    textAlign: 'left',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    color: '#000',
                                }}
                            >
                                Tax
                            </th>
                            <th
                                style={{
                                    border: '1px solid black',
                                    padding: '5px 5px',
                                    textAlign: 'left',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    color: '#000',
                                }}
                            >
                                Unit price
                            </th>
                            <th
                                style={{
                                    border: '1px solid black',
                                    padding: '5px 5px',
                                    textAlign: 'left',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    color: '#000',
                                }}
                            >
                                Total price
                            </th>
                        </tr>
                    </tbody>
                    <tbody style={{ height: '400px', verticalAlign: 'top' }}>
                        {data?.invoiceItems.map((item, key) => (
                            <tr
                                key={key}
                                style={{ borderBottom: '1px solid black' }}
                            >
                                <td
                                    style={{
                                        border: '1px solid black',
                                        padding: '8px 4px',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        color: '#000',
                                    }}
                                >
                                    {item.item.code}
                                </td>
                                <td
                                    style={{
                                        border: '1px solid black',
                                        padding: '8px 4px',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        color: '#000',
                                    }}
                                >
                                    {item.item.name}
                                </td>
                                <td
                                    style={{
                                        border: '1px solid black',
                                        padding: '8px 4px',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        color: '#000',
                                    }}
                                >
                                    {item.quantity}
                                </td>
                                <td
                                    style={{
                                        border: '1px solid black',
                                        padding: '8px 4px',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        color: '#000',
                                    }}
                                >
                                    {item.item.tax_type.code}
                                </td>
                                <td
                                    style={{
                                        border: '1px solid black',
                                        padding: '8px 4px',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        color: '#000',
                                    }}
                                >
                                    {format.currency(item.unit_price)}
                                </td>
                                <td
                                    style={{
                                        border: '1px solid black',
                                        padding: '8px 4px',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        color: '#000',
                                    }}
                                >
                                    {format.currency(
                                        item.quantity * item.unit_price
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex gap-4 flex-row items-start justify-between">
                <div className="space-y-1 text-left text-xs font-normal">
                    {data?.status === 'approved' && (
                        <>
                            <p>
                                <span className="font-semibold">
                                    SDC INFORMATION
                                </span>
                            </p>
                            <div className="font-bold">
                                ----------------------------------
                            </div>
                            <p>
                                <span className="font-semibold">Date</span>:{' '}
                                {dayjs(data?.created_at).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                )}
                            </p>
                            <p>
                                <span className="font-semibold">SDC ID</span>:{' '}
                                {data?.response?.data.sdcId}
                            </p>
                            <p>
                                <span className="font-semibold">
                                    Receipt Number
                                </span>
                                : {data?.response?.data.rcptNo}/
                                {data?.response?.data.totRcptNo}NS
                            </p>
                            <p>
                                <span className="font-semibold">
                                    Internal Data
                                </span>
                                : {data?.response?.data.intrlData}
                            </p>
                            <p>
                                <span className="font-semibold">
                                    Receipt Signature
                                </span>
                                : {data?.response?.data.rcptSign}
                            </p>
                            <div className="font-bold">
                                ----------------------------------
                            </div>
                            <p>
                                <span className="font-semibold">
                                    Receipt Number
                                </span>
                                : {data?.invoice_no}
                            </p>
                            <p>
                                <span className="font-semibold">Date</span>:{' '}
                                {dayjs(data?.created_at).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                )}
                            </p>
                            <p>
                                <span className="font-semibold">MRC</span>:{' '}
                                {data?.response?.data.mrcNo}
                            </p>
                            <div className="font-bold">
                                ----------------------------------
                            </div>
                            <p>Powered by EBM v2</p>
                        </>
                    )}
                </div>

                <div className="flex flex-col items-end h-[140px]  space-y-1 justify-end">
                    {data?.status === 'approved' && (
                        <img
                            alt="qr-code"
                            src={`https://quickchart.io/qr?text=
								Date : ${dayjs(data?.created_at).format('DD/MM/YYYY HH:mm:ss')} \n \t
								SDC ID : ${data?.response?.data.sdcId} \n \t
								RECEIPT NUMBER : ${data?.response?.data.rcptNo}/${
                                data?.response?.data.totRcptNo
                            }NS \n \t
								Internal Data : ${data?.response?.data.intrlData} \n \t
								Receipt Singnature : ${data?.response?.data.rcptSign} \n \t
								`}
                            className="w-[9rem] object-cover"
                        />
                    )}
                </div>

                <div className="flex flex-col items-start text-xs justify-start">
                    <div className="overflow-x-auto">
                        <div className="table-auto border-collapse border border-gray-800">
                            <tbody>
                                <tr>
                                    <td className="border border-black px-1 py-2 text-center">
                                        Total Rwf
                                    </td>
                                    <td className="border border-black px-1 py-2 min-w-[120px] text-center">
                                        {format.currency(
                                            data?.total_amount ?? 0
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-black px-1 py-2 min-w-[120px] text-center">
                                        Total A-EX Rwf
                                    </td>
                                    <td className="border border-black px-1 py-2 min-w-[120px] text-center">
                                        {format.currency(
                                            data?.total_amount ?? 0
                                        )}
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*	<td className="border border-black px-4 py-2 text-center">Total b-18% Rwf</td>*/}
                                {/*	<td className="border border-black px-4 py-2 text-center">{format.currency(data?.total_taxable_amount ?? 0)}</td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td className="border border-black px-1 py-2 min-w-[120px] text-center">
                                        Total Tax - A
                                    </td>
                                    <td className="border border-black px-1 py-2 min-w-[120px] text-center">
                                        {format.currency(
                                            data?.total_tax_amount ?? 0
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-black px-1 py-2 min-w-[120px] text-center">
                                        Total Tax Amount
                                    </td>
                                    <td className="border border-black px-1 py-2 min-w-[120px] text-center">
                                        {format.currency(
                                            data?.total_tax_amount ?? 0
                                        )}
                                    </td>
                                </tr>
                                {/* Add more rows as needed */}
                            </tbody>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
